import CloudFun, { Application, MessageType, Policy } from "@cloudfun/core";
import model from "./models";
import router from "./router";
import sitemap from "./sitemap";
import messages from "./locales";

import App from "./App.vue";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";
import Toastify from "toastify-js";

import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
// Midone Theme
import "./assets/sass/app.scss";
import * as Facebook from "@/components/facebook-login";
import "./router-permission";
import { createI18n } from "vue-i18n";
import VueGapi from "vue-gapi";
import { createPinia } from "pinia";

Facebook.initFacebook(process.env.VUE_APP_FB_APP_ID);
const app = new Application(
  App,
  model,
  new Policy(model, {
    router,
    sitemap,
    loginRoute: "/login",
    guard: (to, from, next) => {
      next();
    }
  }),
  createI18n({ locale: "zh-Hant", fallbackLocale: "zh-Hant", messages })
);

app.use(VueGapi, {
  apiKey: "AIzaSyC7h-DMd4TeYSB6MqaaQcPo7vhrtXFQgmI",
  clientId:
    "40054498844-2pf4dflqgiadqqanmh1neglkblqs88cr.apps.googleusercontent.com",
  discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  scope: "https://www.googleapis.com/auth/spreadsheets"
});
// app.use(GAuth, googleOptions);

// 將 JS 攔截到的錯誤轉給 CloudfunVue Messenger
window.onerror = (message, source, lineno, colno) => {
  if (message === "ResizeObserver loop limit exceeded") return false;
  app.send("log", {
    createdTime: new Date(),
    type: MessageType.Error,
    content: app.user
      ? `[${app.user.Name}] \r\n${message}\r\n    at ${source}:${lineno}:${colno}`
      : `\r\n${message}\r\n    at ${source}:${lineno}:${colno}`
  });
  return true;
};

// 將 Vue.js 攔截到的錯誤轉給 CloudfunVue Messenger
// app.config.errorHandler = (error: any, instance, info) => {
//   // let content = app.user ? `[${app.user.Name}] ${info}: ` : `${info}: `
//   // if (typeof error === 'string') content += error
//   // else content += `${error.message}\r\n${error.stack}`
//   console.log(error);
//   // app.send('log', {
//   //   createdTime: new Date(),
//   //   type: MessageType.Error,
//   //   content
//   // })
// };

// 設定信差中介程序
CloudFun.setMiddlewares({
  info: message =>
    Toastify({
      text: `${
        typeof message === "string"
          ? "Information"
          : message.subject || "Information"
      }: ${typeof message === "string" ? message : message.content}`,
      close: true,
      gravity: "bottom",
      position: "left",
      style: { background: "#10b981" },
      stopOnFocus: true
    }).showToast(),
  warning: message =>
    Toastify({
      text: `${
        typeof message === "string" ? "Warning" : message.subject || "Warning"
      }: ${typeof message === "string" ? message : message.content}`,
      close: true,
      gravity: "bottom",
      position: "left",
      style: { background: "#f5970f" },
      stopOnFocus: true
    }).showToast(),
  error: message =>
    Toastify({
      text: `${
        typeof message === "string" ? "Error" : message.subject || "Error"
      }: ${typeof message === "string" ? message : message.content}`,
      close: true,
      gravity: "bottom",
      position: "left",
      style: { background: "#ef4444" },
      stopOnFocus: true
    }).showToast(),
  "application.error": error => console.log(error)
});

globalComponents(app);
utils(app);

app.use(VXETable);
app.use(createPinia());
VXETable.setup({ i18n: (key, args) => app.i18n.global.t(key, args) });

app.run("#app");
