import router from "./router";

router.beforeEach((to, from, next) => {
  const user = sessionStorage.getItem("EmptyNext-user");

  if (to.matched.some(record => record.meta.auth)) {
    if (user) {
      return next();
    } else {
      const redirect = to.path !== "/login" ? `${to.path}` : "";
      return next({ path: `/login`, query: { returnUrl: redirect } });
    }
  } else {
    return next();
  }
});
