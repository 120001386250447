import { AuthorizationType, Model } from "@cloudfun/core";
import { main, Store as MainStore, State as MainState } from "./main";
import configuration from "./configuration";
import role from "./role";
import user from "./user";
import actionLog from "./action-log";
import category from "./category";
import bannerPosition from "./banner-position";
import banner from "./banner";
import pictureAlbum from "./picture-album";
import picture from "./picture";
import attribute from "./attribute";
import attributeValue from "./attribute-value";
import product from "./product";
import option from "./option";
import system from "./system";
import register from "./register";
import news from "./news";
import member from "./member";
import vendor from "./vendor";
import vendorMember from "./vendor-member";
import image from "./image";
import message from "./message";
import consultation from "./consultation";
import meeting from "./meeting";

const model = new Model("EmptyNext", {
  main,
  configuration,
  role,
  user,
  actionLog,
  category,
  bannerPosition,
  banner,
  pictureAlbum,
  picture,
  attribute,
  attributeValue,
  product,
  option,
  system,
  register,
  news,
  member,
  vendor,
  image,
  message,
  consultation,
  meeting,
  vendorMember
});

const backendServer = `${process.env.VUE_APP_BACKEND_URL}`;
model.clients.authorized = model.createHttpClient(
  `${backendServer}/api`,
  true,
  AuthorizationType.Bearer
);
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`);
model.onLogin = data =>
  model.clients.unauthorized.post("System/Login", {
    account: data.account,
    password: data.password,
    token: data.password,
    LastVisitedUri: data.token,
    LoginIp: data.captcha
  });
model.onLogout = () => model.clients.authorized.post("System/Logout");
model.onReloadUser = () => model.clients.authorized.post("System/CurrentUser");
// model.onLog = (message) => model.clients.unauthorized.post('System/Log', message)

export type State = { main: MainState };
export type Store = MainStore<Pick<State, "main">>;

export default model;
