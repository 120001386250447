/* eslint-disable */

import { deepEqual } from "fast-equals";
import { ModelState, IJsonResponse, IUserData } from "@cloudfun/core";
import { Module } from "vuex";
import CloudFun from "@cloudfun/core";
import axios from "axios";

const MAX_ALIVE_TIME = 15000;
const apiPath = "/v1/Product";

const apiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/v1/Vendor`;
//const user = JSON.parse(sessionStorage.getItem('EmptyNext-user')!);

// const headers = {
//   "Content-Type": "application/json",
//   "Authorization": `Bearer ${user?.token}`,
// }

//https://digitalexhibitiondemo.azurewebsites.net/api/v1/Product?pageSize=10&page=1 https://cors-anywhere.herokuapp.com/
interface State {
  loadingTime?: Date;
  params: any;
  totalCount: number;
  rows: any[];
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    params: {},
    totalCount: 0,
    rows: []
  },
  getters: {
    isExpired: state =>
      !state.loadingTime ||
      new Date().valueOf() - state.loadingTime.valueOf() < MAX_ALIVE_TIME,
    page: state => state.params.page,
    pageSize: state => state.params.pageSize,
    condition: state => state.params.condition,
    sortings: state => state.params.sortings,
    totalCount: state => state.totalCount,
    rows: state => state.rows
  },
  mutations: {
    setPage(state, page) {
      if (state.params.page == page) return;
      state.params.page = page;
      state.loadingTime = undefined;
    },
    setPageSize(state, pageSize) {
      if (state.params.pageSize == pageSize) return;
      state.params.pageSize = pageSize;
      state.loadingTime = undefined;
    },
    setCondition(state, condition) {
      if (deepEqual(state.params.condition, condition)) return;
      state.params.condition = condition;
      state.loadingTime = undefined;
    },
    setSortings(state, sortings) {
      if (deepEqual(state.params.sortings, sortings)) return;
      state.params.sortings = sortings;
      state.loadingTime = undefined;
    },
    setParams(state, params) {
      if (deepEqual(state.params, params)) return;
      state.params = params;
      state.loadingTime = undefined;
    },
    setRows(state, rows) {
      state.loadingTime = new Date();
      state.rows = rows;
    },
    setTotalCount(state, totalCount) {
      state.loadingTime = new Date();
      state.totalCount = totalCount;
    },
    setLoadingTime: (state, time) => (state.loadingTime = time)
  },
  actions: {
    find: ({ rootState, state, getters }, key) => {
      const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiUrl}/Members/${key}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`
            }
          })
          .then((response: any) => {
            resolve(response.data.payload);
          })
          .catch((error: any) => {
            console.log(error.response);
            reject(error.response.data);
          });
      });
    },
    insert: ({ rootState, commit }, data) => {
      const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
      return new Promise((resolve, reject) => {
        debugger;
        axios
          .post(`${apiUrl}/Members`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`
            }
          })
          .then((response: any) => {
            console.log(response);
            resolve(response.data.payload);
          })
          .catch((error: any) => {
            console.log(error.response);
            reject(error.response.data);
          });
      });
    },
    update: ({ rootState, commit }, data) => {
      const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Members/${data.memberID}`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`
            }
          })
          .then((response: any) => {
            console.log(response);
            resolve(response.data.payload);
          })
          .catch((error: any) => {
            console.log(error.response);
            reject(error.response.data);
          });
      });
    },
    delete: ({ rootState, commit }, data) => {
      const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiUrl}/Members/${data}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`
            }
          })
          .then((response: any) => {
            console.log(response);
            resolve(response.data.payload);
          })
          .catch((error: any) => {
            console.log(error.response);
            reject(error.response.data);
          });
      });
    },
    // update: ({ rootState, commit }, data) => new Promise<IUserData>((resolve, reject) => {
    //   rootState.clients.authorized.put(apiPath, data).then(
    //     (success: IJsonResponse<IUserData>) => {
    //       commit('setLoadingTime', null);
    //       resolve(success.payload!);
    //     },
    //     (failure: IJsonResponse<any>) => reject(failure.message)
    //   );
    // }),
    query: ({ rootState }, params) => {
      const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiUrl}/Members`, {
            params,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`
            }
          })
          .then((response: any) => {
            resolve(response.data.payload);
          })
          .catch((error: any) => {
            reject(error.response.data.errors);
          });
      });
    },
    vendor: ({ rootState }) => {
      const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiUrl}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`
            }
          })
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.response.data.errors);
          });
      });
    },
    save: ({ rootState }, data) => {
      const user = JSON.parse(sessionStorage.getItem("EmptyNext-user")!);
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Members`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.token}`
            }
          })
          .then((response: any) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            reject(error.response.data.errors);
          });
      });
    },
    // query: ({ rootState }, params) => new Promise((resolve, reject) => {
    //   const user = JSON.parse(sessionStorage.getItem('EmptyNext-user')!);
    //   console.log(user)
    //   rootState.clients.unauthorized.get(apiPath, { params,
    //     headers: {
    //       "Authorization": `Bearer ${user?.token}`,
    //       "Content-Type": "application/json",
    //     } }).then(
    //     (success: IJsonResponse<any>) => resolve(success.payload),
    //     (failure: IJsonResponse<any>) => reject(failure)
    //   )
    // }),
    load: ({ rootState, state, commit }, params = null) =>
      new Promise((resolve, reject) => {
        if (params) commit("setParams", params);
        params = { ...state.params };
        console.log("send params: ", JSON.stringify(params));
        rootState.clients.authorized.get(apiPath, { params }).then(
          (success: IJsonResponse<any>) => {
            commit("setLoadingTime", new Date());
            commit("setTotalCount", success.payload.totalCount);
            commit("setRows", success.payload.data);
            resolve(success.payload);
          },
          (failure: IJsonResponse<any>) => reject(failure.message)
        );
      })
  }
};

export default module;
