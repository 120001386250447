/* eslint-disable */

import { deepEqual } from "fast-equals";
import { ModelState, IJsonResponse, IUserData } from "@cloudfun/core";
import { Module } from "vuex";
import CloudFun from "@cloudfun/core";
import axios from "axios";

const MAX_ALIVE_TIME = 15000;

const apiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/v1`;

const headers = {
  "Content-Type": "application/json"
};

interface State {
  loadingTime?: Date;
  params: any;
  totalCount: number;
  rows: any[];
}

const module: Module<State, ModelState> = {
  namespaced: true,
  state: {
    params: {},
    totalCount: 0,
    rows: []
  },
  getters: {
    isExpired: state =>
      !state.loadingTime ||
      new Date().valueOf() - state.loadingTime.valueOf() < MAX_ALIVE_TIME,
    page: state => state.params.page,
    pageSize: state => state.params.pageSize,
    condition: state => state.params.condition,
    sortings: state => state.params.sortings,
    totalCount: state => state.totalCount,
    rows: state => state.rows
  },
  mutations: {
    setPage(state, page) {
      if (state.params.page == page) return;
      state.params.page = page;
      state.loadingTime = undefined;
    },
    setPageSize(state, pageSize) {
      if (state.params.pageSize == pageSize) return;
      state.params.pageSize = pageSize;
      state.loadingTime = undefined;
    },
    setCondition(state, condition) {
      if (deepEqual(state.params.condition, condition)) return;
      state.params.condition = condition;
      state.loadingTime = undefined;
    },
    setSortings(state, sortings) {
      if (deepEqual(state.params.sortings, sortings)) return;
      state.params.sortings = sortings;
      state.loadingTime = undefined;
    },
    setParams(state, params) {
      if (deepEqual(state.params, params)) return;
      state.params = params;
      state.loadingTime = undefined;
    },
    setRows(state, rows) {
      state.loadingTime = new Date();
      state.rows = rows;
    },
    setTotalCount(state, totalCount) {
      state.loadingTime = new Date();
      state.totalCount = totalCount;
    },
    setLoadingTime: (state, time) => (state.loadingTime = time)
  },
  actions: {
    captcha: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .get(`${apiUrl}/Auth/Login`)
          .then((response: any) => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response.data.errors);
          });
      }),
    login: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Auth/Login`, data, { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    verify: (
      { rootState },
      data //傳送驗證碼
    ) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Auth/Signup/Verify`, data, { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    send: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Auth/Signup/Send`, data, { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    resend: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Verification/Resend`, data, { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    check: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Auth/Check`, data, { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    facebookLogin: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Auth/Login/Facebook`, data, { headers })
          .then(response => {
            // console.log("response", response);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    googleLogin: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Auth/Login/Google`, data, { headers })
          .then(response => {
            // console.log("response", response);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    forgotPassword: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/ForgotPassword/Submit`, data, { headers })
          .then(response => {
            // console.log("response", response);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    forgotPasswordSend: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/ForgotPassword/Send`, data, { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    forgotPasswordChange: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/ForgotPassword/Change`, data, { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    forgotPasswordVerify: ({ rootState }, data) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/ForgotPassword/Verify`, data, { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    serviceCategories: ({ rootState }) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Option`, ["Service_Categories"], { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      }),
    productCategories: ({ rootState }) =>
      new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/Option`, ["Product_Categories"], { headers })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      })
  }
};

export default module;
