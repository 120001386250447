import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "HomeIcon",
      to: "dashboard",
      title: "首頁與總覽"
    },
    // {
    //   icon: "fa-columns",
    //   title: "選單安排",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "/dashboard",
    //       title: "側邊選單",
    //       ignore: true
    //     },
    //     {
    //       icon: "",
    //       to: "/simple-menu/dashboard",
    //       title: "簡易選單",
    //       ignore: true
    //     },
    //     {
    //       icon: "",
    //       to: "/top-menu/dashboard",
    //       title: "上方選單",
    //       ignore: true
    //     }
    //   ]
    // },
    {
      icon: "MailIcon",
      to: "announcement",
      title: "公告與通知"
    },
    {
      icon: "MessageSquareIcon",
      title: "商洽與媒合",
      to: "meeting",
      subNodes: [
        {
          icon: "",
          to: "inquiry-mailbox",
          title: "業務洽詢信箱"
        },
        // {
        //   icon: "",
        //   to: "meeting",
        //   title: "預約線上會議室"
        // },
        {
          icon: "",
          to: "meeting",
          title: "會議排程與紀錄"
        }
      ]
    },
    "devider",
    // {
    //   icon: 'SettingsIcon',
    //   to: 'configuration',
    //   title: '系統配置'
    // },
    {
      icon: "UserIcon",
      to: "user",
      title: "會員個人資料",
      subNodes: [
        // {
        //   icon: 'fa-magic',
        //   to: 'user-wizard',
        //   title: '用戶註冊精靈'
        // },
        {
          icon: "",
          to: "user",
          title: "基本資料閱覽與管理"
        },
        {
          icon: "",
          to: "user-password",
          title: "密碼管理"
        }
      ]
    },
    {
      icon: "BriefcaseIcon",
      to: "company",
      title: "供應商企業資料"
    },
    {
      icon: "ArrowUpCircleIcon",
      to: "company/application",
      title: "供應商企業資料"
    },
    {
      icon: "BriefcaseIcon",
      to: "midone-modal",
      title: "Modal"
    },
    {
      icon: "BriefcaseIcon",
      to: "company/upgrade",
      title: "供應商企業資料"
    },
    {
      icon: "UsersIcon",
      to: "member",
      title: "企業成員管理",
      subNodes: [
        {
          icon: "UsersIcon",
          to: "member",
          title: "成員管理"
        },
        {
          icon: "UserCheckIcon",
          to: "review/apply",
          title: "帳號申請審核"
        }
      ]
    },
    "devider",
    {
      icon: "BoxIcon",
      to: "product",
      title: "產品與服務管理",
      subNodes: [
        {
          icon: "",
          to: "product",
          title: "產品與服務分類管理"
        }
        // {
        //   icon: '',
        //   to: '',
        //   title: '產品與服務編輯'
        // }
      ]
    }
    // {
    //   icon: 'ActivityIcon',
    //   to: 'action-log',
    //   title: '操作紀錄'
    // },
    // {
    //   icon: 'fa-sitemap',
    //   to: 'category',
    //   title: '類別管理'
    // },
    // {
    //   icon: 'FlagIcon',
    //   to: 'banner',
    //   title: '廣告管理'
    // },
    // {
    //   icon: 'ImageIcon',
    //   to: 'album',
    //   title: '相簿管理'
    // },
    // {
    //   icon: 'fa-list',
    //   to: 'attribute',
    //   title: '屬性管理'
    // },
    // "devider"
    // {
    //   icon: 'fa-tachometer-alt',
    //   to: 'midone-dashboard',
    //   title: 'Dashboard'
    // },
    // {
    //   icon: 'InboxIcon',
    //   to: 'midone-inbox',
    //   title: 'Inbox'
    // },
    // {
    //   icon: 'HardDriveIcon',
    //   to: 'midone-file-manager',
    //   title: 'File Manager'
    // },
    // {
    //   icon: 'CreditCardIcon',
    //   to: 'midone-point-of-sale',
    //   title: 'Point of Sale'
    // },
    // {
    //   icon: 'MessageSquareIcon',
    //   to: 'midone-chat',
    //   title: 'Chat'
    // },
    // {
    //   icon: 'FileTextIcon',
    //   to: 'midone-post',
    //   title: 'Post'
    // },
    // 'devider',
    // {
    //   icon: 'EditIcon',
    //   to: 'midone-crud-data-list',
    //   title: 'Crud',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: 'midone-crud-data-list',
    //       title: 'Data List'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-crud-form',
    //       title: 'Form'
    //     }
    //   ]
    // },
    // {
    //   icon: 'UsersIcon',
    //   to: 'midone-users-layout-1',
    //   title: 'Users',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: 'midone-users-layout-1',
    //       title: 'Layout 1'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-users-layout-2',
    //       title: 'Layout 2'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-users-layout-3',
    //       title: 'Layout 3'
    //     }
    //   ]
    // },
    // {
    //   icon: 'TrelloIcon',
    //   to: 'midone-profile-overview-1',
    //   title: 'Profile',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: 'midone-profile-overview-1',
    //       title: 'Overview 1'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-profile-overview-2',
    //       title: 'Overview 2'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-profile-overview-3',
    //       title: 'Overview 3'
    //     }
    //   ]
    // },
    // {
    //   icon: 'LayoutIcon',
    //   to: 'midone-wizard-layout-1',
    //   title: 'Pages',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: 'midone-wizard-layout-1',
    //       title: 'Wizards',
    //       subNodes: [
    //         {
    //           icon: '',
    //           to: 'midone-wizard-layout-1',
    //           title: 'Layout 1'
    //         },
    //         {
    //           icon: '',
    //           to: 'midone-wizard-layout-2',
    //           title: 'Layout 2'
    //         },
    //         {
    //           icon: '',
    //           to: 'midone-wizard-layout-3',
    //           title: 'Layout 3'
    //         }
    //       ]
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-blog-layout-1',
    //       title: 'Blog',
    //       subNodes: [
    //         {
    //           icon: '',
    //           to: 'midone-blog-layout-1',
    //           title: 'Layout 1'
    //         },
    //         {
    //           icon: '',
    //           to: 'midone-blog-layout-2',
    //           title: 'Layout 2'
    //         },
    //         {
    //           icon: '',
    //           to: 'midone-blog-layout-3',
    //           title: 'Layout 3'
    //         }
    //       ]
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-pricing-layout-1',
    //       title: 'Pricing',
    //       subNodes: [
    //         {
    //           icon: '',
    //           to: 'midone-pricing-layout-1',
    //           title: 'Layout 1'
    //         },
    //         {
    //           icon: '',
    //           to: 'midone-pricing-layout-2',
    //           title: 'Layout 2'
    //         }
    //       ]
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-invoice-layout-1',
    //       title: 'Invoice',
    //       subNodes: [
    //         {
    //           icon: '',
    //           to: 'midone-invoice-layout-1',
    //           title: 'Layout 1'
    //         },
    //         {
    //           icon: '',
    //           to: 'midone-invoice-layout-2',
    //           title: 'Layout 2'
    //         }
    //       ]
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-faq-layout-1',
    //       title: 'FAQ',
    //       subNodes: [
    //         {
    //           icon: '',
    //           to: 'midone-faq-layout-1',
    //           title: 'Layout 1'
    //         },
    //         {
    //           icon: '',
    //           to: 'midone-faq-layout-2',
    //           title: 'Layout 2'
    //         },
    //         {
    //           icon: '',
    //           to: 'midone-faq-layout-3',
    //           title: 'Layout 3'
    //         }
    //       ]
    //     },
    //     {
    //       icon: '',
    //       to: 'login',
    //       title: 'Login'
    //     },
    //     {
    //       icon: '',
    //       to: 'register',
    //       title: 'Register'
    //     },
    //     {
    //       icon: '',
    //       to: 'error-page',
    //       title: 'Error Page'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-update-profile',
    //       title: 'Update profile'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-change-password',
    //       title: 'Change Password'
    //     }
    //   ]
    // },
    // "devider",
    // {
    //   icon: "InboxIcon",
    //   to: "midone-regular-table",
    //   title: "Components",
    //   subNodes: [
    //     {
    //       icon: "",
    //       to: "midone-regular-table",
    //       title: "Table",
    //       subNodes: [
    //         {
    //           icon: "",
    //           to: "midone-regular-table",
    //           title: "Regular Table"
    //         },
    //         {
    //           icon: "",
    //           to: "midone-tabulator",
    //           title: "Tabulator"
    //         }
    //       ]
    //     },
    //     {
    //       icon: "",
    //       to: "midone-accordion",
    //       title: "Accordion"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-button",
    //       title: "Button"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-modal",
    //       title: "Modal"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-alert",
    //       title: "Alert"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-progress-bar",
    //       title: "Progress Bar"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-tooltip",
    //       title: "Tooltip"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-dropdown",
    //       title: "Dropdown"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-toast",
    //       title: "Toast"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-typography",
    //       title: "Typography"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-icon",
    //       title: "Icon"
    //     },
    //     {
    //       icon: "",
    //       to: "midone-loading-icon",
    //       title: "Loading Icon"
    //     }
    //   ]
    // }
    // {
    //   icon: 'SidebarIcon',
    //   to: 'midone-regular-form',
    //   title: 'Forms',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: 'midone-regular-form',
    //       title: 'Regular Form'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-datepicker',
    //       title: 'Datepicker'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-tail-select',
    //       title: 'Tail Select'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-file-upload',
    //       title: 'File Upload'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-wysiwyg-editor',
    //       title: 'Wysiwyg Editor'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-validation',
    //       title: 'Validation'
    //     }
    //   ]
    // },
    // {
    //   icon: 'HardDriveIcon',
    //   to: 'midone-chart',
    //   title: 'Widgets',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: 'midone-chart',
    //       title: 'Chart'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-slider',
    //       title: 'Slider'
    //     },
    //     {
    //       icon: '',
    //       to: 'midone-image-zoom',
    //       title: 'Image Zoom'
    //     }
    //   ]
    // }
  ]
};

export default sitemap;
